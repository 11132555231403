/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
 /*jslint eqeq: true*/

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(function() {
          $('a[href*="#"]:not([href="#"]):not(.scrollup)').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
              if (target.length) {
                $('html, body').animate({
                  scrollTop: target.offset().top
                }, 1000);
                return false;
              }
            }
          });
        });

        $(window).scroll(function () {
          if ($(this).scrollTop() > 100) {
            $('.scrollup').fadeIn();
          } else {
            $('.scrollup').fadeOut();
          }
        });

        $('.scrollup').click(function () {
          $("html, body").animate({
            scrollTop: 0
          }, 600);
          return false;
        });

        $(".services-carousel").slick({
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          nextArrow: '<i class="fa fa-angle-right"></i>',
          prevArrow: '<i class="fa fa-angle-left"></i>',
          autoplay: true,
          autoplaySpeed: 3000,
          speed: 2000,
          responsive: [
            {
            breakpoint: 991,
              settings: {
                arrows:false,
              }
            },
            {
              breakpoint: 425,
              settings: {
                slidesToShow: 2,
                arrows:false,
              }
            },
          ]
        });

        $(".testimonial-carousel").slick({
          dots: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6000,
          speed: 1000
        });

        $(".brand-carousel").slick({
          infinite: true,
          slidesToShow: 4,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint:1024,
              settings: {
                infinite: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows:false,
                autoplay: true,
                autoplaySpeed: 2000,
                speed: 1100,
              }
            },
            {
              breakpoint:767,
              settings: {
                infinite: true,
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows:false,
                autoplay: true,
                autoplaySpeed: 2000,
                speed: 1100,
              }
            },
            {
              breakpoint:480,
              settings: {
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows:false,
                autoplay: true,
                autoplaySpeed: 2000,
                speed: 1100,
              }
            }
          ]
        });

        // Hamburger Menu
        var removeClass = true;
          $(".hamburger").click(function () {
              $(".hamburger").toggleClass('is-active');
              $("#sideNav").toggleClass('sideNav-open');
              $(".sideNavBody").toggleClass('sideNavBody-push');
              removeClass = false;
          });

          $(".sideNav").click(function() {
              removeClass = false;
          });

          $("html").click(function () {
              if (removeClass) {
                  $(".hamburger").removeClass('is-active');
                  $("#sideNav").removeClass('sideNav-open');
                  $(".sideNavBody").removeClass('sideNavBody-push');
              }
              removeClass = true;
          });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'contact': {
      init: function() {
        // JavaScript to be fired on the about us page

        function hideOldCust(x) {
         if (x.checked) {
           document.getElementById("A").style.display = "none";
           document.getElementById("B").style.display = "initial";
         }
       }

       function hideNewCust(x) {
         if (x.checked) {
           document.getElementById("B").style.display = "none";
           document.getElementById("A").style.display = "initial";
         }
       }

       $('.new-customer').change(function(){
         hideNewCust(this);
       });

       $('.old-customer').change(function(){
         hideOldCust(this);
       });

       function initialize_gmap(point_lat, point_long, address, title, directions) {
          var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

          var isDraggable = w > 480 ? true : false;
          var point = new google.maps.LatLng(point_lat, point_long);
          var map_lat = point_lat + 0.002812;

          var settings = {
            zoom: 15,
            center: {lat: map_lat, lng: point_long},
            scrollwheel: false,
            draggable: isDraggable,
            mapTypeControl: true,
            mapTypeControlOptions: {style: google.maps.MapTypeControlStyle.DROPDOWN_MENU},
            navigationControl: true,
            navigationControlOptions: {style: google.maps.NavigationControlStyle.SMALL},
            mapTypeId: google.maps.MapTypeId.ROADMAP};

          var map = new google.maps.Map(document.getElementById("map_canvas"), settings);

          var contentString = '<div id="content" style="text-align:left;">'+
            '<div id="siteNotice">'+
            '</div>'+
            '<a href="' + directions + '" target="_blank"><h3>' + title + '<br><small>(Click for directions)</small></h3></a>'+
            '<div id="bodyContent">'+
            '<p>' + address + '</p>'+
            '</div>'+
            '</div>';

          var infowindow = new google.maps.InfoWindow({
            content: contentString
          });

          var companyMarker = new google.maps.Marker({
            position: point,
            map: map,
            title: title});

          infowindow.open(map,companyMarker);

          var center;
          function calculateCenter() {
            center = map.getCenter();
          }
          google.maps.event.addDomListener(map, 'idle', function() {
            calculateCenter();
          });
          google.maps.event.addDomListener(window, 'resize', function() {
            map.setCenter(center);
          });
        }

        if($('#map_canvas').length) {
          var point_lat = parseFloat($('.map-data .point_lat').data('pointlat'));
          var point_long = parseFloat($('.map-data .point_long').data('pointlong'));
          var address = $('.map-data .address').data('address');
          var title = $('.map-data .title').data('title');
          var directions = $('.map-data .directions').data('directions');
          initialize_gmap(point_lat, point_long, address, title, directions);
        }

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
